<template>
  <page-toggle-transition :disabled="animate.disabled" :animate="animate.name" :direction="animate.direction">
    <router-view class="blankView" />
  </page-toggle-transition>
</template>

<script>
import PageToggleTransition from '../components/transition/PageToggleTransition'
import { mapState } from 'vuex'

export default {
  name: 'BlankView',
  components: { PageToggleTransition },
  computed: {
    ...mapState('setting', ['multiPage', 'animate'])
  }
}
</script>

<style scoped style="less">
.blankView{
  width:100%;
  height:100%;
}
</style>
