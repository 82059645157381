var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-toggle-transition",
    {
      attrs: {
        disabled: _vm.animate.disabled,
        animate: _vm.animate.name,
        direction: _vm.animate.direction,
      },
    },
    [_c("router-view", { staticClass: "blankView" })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }